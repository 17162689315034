/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { useWindowDimensions } from "~hooks";

import mintFront from "~assets/css3d/box-mint/front.jpg";
import mintSideLeft from "~assets/css3d/box-mint/side-left.jpg";
import hazelnutFront from "~assets/css3d/box-hazelnut/four-pack-front.png";
import hazelnutSideLeft from "~assets/css3d/box-hazelnut/four-pack-side-left.png";
import hazelnutTop from "~assets/css3d/box-hazelnut/top.png";
import caramelFront from "~assets/css3d/box-caramel/four-pack-front.png";
import caramelSideLeft from "~assets/css3d/box-caramel/four-pack-side-left.png";
import almondFront from "~assets/css3d/box-almond/front.jpg";
import almondSideLeft from "~assets/css3d/box-almond/side-left.jpg";

const BOX_DEPTH_SCALE = 0.5;
const BOX_HEIGHT_SCALE = 1.55;

const ChocoPopsBox = ({ flavour, visible, loadIn = `` }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { windowSize } = useWindowDimensions();

  const [boxDimensions, setBoxDimensions] = useState({
    width: 0,
    height: 0,
    depth: 0
  });

  let loadAnimation = `load-in`;

  if (loadIn === `left`) {
    loadAnimation = `load-in-lean-left`;
  }

  if (loadIn === `right`) {
    loadAnimation = `load-in-lean-right`;
  }

  const dimensionsRef = useRef();

  const images = {
    almond: {
      front: almondFront,
      left: almondSideLeft,
      top: null
    },
    caramel: {
      front: caramelFront,
      left: caramelSideLeft,
      top: null
    },
    mint: {
      front: mintFront,
      left: mintSideLeft,
      top: null
    },
    hazelnut: {
      front: hazelnutFront,
      left: hazelnutSideLeft,
      top: hazelnutTop
    }
  };

  const frontImage = images?.[flavour?.id]?.front;
  const leftImage = images?.[flavour?.id]?.left;
  const topImage = images?.[flavour?.id]?.top;

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    if (!dimensionsRef?.current) {
      return;
    }

    const { width } = dimensionsRef.current.getBoundingClientRect();

    const height = width * BOX_HEIGHT_SCALE;
    const depth = width * BOX_DEPTH_SCALE;

    setBoxDimensions({
      width,
      height,
      depth
    });
  }, [dimensionsRef, windowSize]);

  // ---------------------------------------------------------------------------
  // render

  return (
    <>
      <div
        ref={dimensionsRef}
        css={[
          css`
            width: 100%;
            height: 100%;
            position: relative;
            opacity: 0;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
          `
        ]}
      />

      {visible && (
        <div
          css={[
            css`
              animation: 3s ease ${loadAnimation} forwards;

              transform: translate3d(-50%, -50%, 0) rotate3d(0, 0, 0, 0)
                rotate3d(0, 0, 0, 0);
              transform-style: preserve-3d;
              transform-style: preserve-3d;

              width: 100%;
              height: ${boxDimensions.height}px;
              position: absolute;
              top: 50%;
              left: 50%;
            `
          ]}
        >
          {/* front */}
          <div
            css={[
              css`
                backface-visibility: hidden;
                overflow: hidden;

                animation: 2s ease blur-in forwards;

                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(
                  -50%,
                  -50%,
                  ${boxDimensions.depth / 2}px
                );
              `
            ]}
          >
            <div
              css={css`
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10;
                background: #ffffff;
                opacity: 0.08;
              `}
            />
            <img
              css={css`
                width: 100%;
                height: 100%;
                position: relative;
                display: block;
                object-fit: cover;
              `}
              src={frontImage}
              alt="Product face front"
            />
          </div>

          {/* back */}
          <div
            css={[
              css`
                backface-visibility: hidden;
                overflow: hidden;

                animation: 5s ease blur-in forwards;

                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(
                    -50%,
                    -50%,
                    -${boxDimensions.depth / 2}px
                  )
                  rotate3d(0, 1, 0, 180deg);
              `
            ]}
          >
            <img
              css={css`
                width: 100%;
                height: 100%;
                position: relative;
                display: block;
                object-fit: cover;
              `}
              src={frontImage}
              alt="Product face back"
            />
          </div>

          {/* left */}
          <div
            css={[
              css`
                backface-visibility: hidden;
                overflow: hidden;

                animation: 5s ease blur-in forwards;

                width: ${boxDimensions.depth}px;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(
                    calc(-50% - ${boxDimensions.width / 2}px),
                    -50%,
                    0
                  )
                  rotate3d(0, 1, 0, 270deg);
              `
            ]}
          >
            <div
              css={css`
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10;
                background: #000000;
                opacity: 0.1;
              `}
            />

            <img
              css={css`
                width: 100%;
                height: 100%;
                position: relative;
                display: block;
                object-fit: cover;
              `}
              src={leftImage}
              alt="Product face left"
            />
          </div>

          {/* right */}
          <div
            css={[
              css`
                backface-visibility: hidden;
                overflow: hidden;

                animation: 5s ease blur-in forwards;

                width: ${boxDimensions.depth}px;
                height: 100%;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(
                    calc(-50% + ${boxDimensions.width / 2}px),
                    -50%,
                    0
                  )
                  rotate3d(0, 1, 0, 90deg);
              `
            ]}
          >
            <div
              css={css`
                width: 100%;
                height: 50%;
                background: ${flavour.backgroundPrimary};
              `}
            />
            <div
              css={css`
                width: 100%;
                height: 50%;
                background: ${flavour.backgroundSecondary};
              `}
            />
          </div>

          {/* top */}
          <div
            css={[
              css`
                transition: background-color 0.3s var(--cubic-easing);
                backface-visibility: hidden;
                overflow: hidden;

                animation: 5s ease blur-in forwards;

                width: ${boxDimensions.width}px;
                height: ${boxDimensions.depth}px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(
                    -50%,
                    calc(-50% - ${boxDimensions.height / 2}px),
                    0
                  )
                  rotate3d(1, 0, 0, 90deg);
                background: ${flavour.backgroundSecondary};
              `
            ]}
          >
            {topImage && (
              <img
                css={css`
                  width: 100%;
                  height: 100%;
                  position: relative;
                  display: block;
                  object-fit: cover;
                `}
                src={topImage}
                alt="Product face top"
              />
            )}
          </div>

          {/* bottom */}
          <div
            css={[
              css`
                transition: background-color 0.3s var(--cubic-easing);
                backface-visibility: hidden;
                overflow: hidden;

                animation: 5s ease blur-in forwards;

                width: ${boxDimensions.width}px;
                height: ${boxDimensions.depth}px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(
                    -50%,
                    calc(-50% + ${boxDimensions.height / 2}px),
                    0
                  )
                  rotate3d(1, 0, 0, 90deg) rotate3d(0, 1, 0, 180deg);
                background: ${flavour.backgroundPrimary};
              `
            ]}
          >
            <div
              css={css`
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10;
                background: #000000;
                opacity: 0.25;
              `}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ChocoPopsBox;
